<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CRow>
                <CCol sm="10">
                  <CIcon name="cil-grid"/>
                  {{ $t('invoice.InvoiceList') }}
                </CCol>
                <CCol sm="2">
                  <CInput type="text"
                          :placeholder="$t('Search')"
                          v-model="search"
                          size="sm">
                    <template #append>
                      <CButton type="submit"
                               color="primary"
                               @click="filterBySearch()">
                        {{ $t('Search') }}
                      </CButton>
                    </template>
                  </CInput>
                </CCol>
              </CRow>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable :hover="false"
                        :striped="false"
                        :bordered="false"
                        :fixed="false"
                        :dark="false"
                        :fields="invoiceListFields"
                        :pagination="false"
                        :items="items"
                        :loading="listLoading"
                        :items-per-page="perpage"
                        :items-per-page-select='{"label": $t("NumberOfItemsPerPage"), "values": [20,50,100]}'
                        @pagination-change="paginationChanged"
                        ref="invoiceTable">
              <template #invoice_no="{item}">
                <td class="invoice-no"
                    @click="showInvoiceDetail(item.invoice_no)">
                  {{ item.invoice_no }}
                </td>
              </template>
              <template #created="{item}">
                <td>
                  {{ $moment(item.created).format('DD/MM/YYYY H:mm') }}
                </td>
              </template>
              <template #modified="{item}">
                <td>
                  {{ $moment(item.modified).format('DD/MM/YYYY H:mm') }}
                </td>
              </template>
            </CDataTable>
            <CPagination
                :activePage.sync="page"
                :pages="total_pages"
                align="center"
                @update:activePage="pageChanged"
              />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal :title="$t('invoice.InvoiceDetail')"
            :show.sync="invoiceDetailModal"
            size="lg"
            ref="modalInvoiceDetail">
      <CRow v-if="invoiceDetail">
        <CCol sm="6">
          <p>
            <b>{{ $t('invoice.InvoiceNo') }}</b>
          </p>
          <p>{{ invoiceDetail.invoice_no }}</p>
        </CCol>
        <CCol sm="6">
          <p>
            <b>{{ $t('invoice.AmountDue') }} (USDT)</b>
          </p>
          <p>{{ invoiceDetail.amount_due }}</p>
        </CCol>
        <CCol sm="6">
          <p>
            <b>{{ $t('invoice.AmountPaid') }} (USDT)</b>
          </p>
          <p>{{ invoiceDetail.amount_received }}</p>
        </CCol>
        <CCol sm="12">
          <CDataTable :hover="false"
                      :striped="false"
                      :bordered="false"
                      :fixed="false"
                      :dark="false"
                      :fields="billListFields"
                      :items="invoiceDetail.transactions"
                      :loading="listLoading"
                      :pagination="false"
                      ref="billingTable">
            <template #bill_no="{item}">
              <td class="bill-no"
                  @click="showBillDetail(item.bill_no)">
                {{ item.bill_no }}
              </td>
            </template>
            <template #subscription="{item}">
              <td>
                {{ item.subscription_history.subscription.cname }}
              </td>
            </template>
            <template #item_name="{item}">
              <td>
                  {{ item.item_name }}
              </td>
            </template>
            <template #amount="{item}">
              <td>
                {{ item.amount_currency1 }}
              </td>
            </template>
            <template #status="{item}">
              <td>
                <CBadge :color="item.status === 0 ? 'secondary' : item.status === -100 ? 'info': item.status < 0 ? 'danger' : item.status > 0 && item.status < 100 ? 'warning' : 'success'" shape="pill">{{ item.status_display }}</CBadge>
              </td>
            </template>
            <template #created="{item}">
              <td>
                {{ $moment(item.created).format('DD/MM/YYYY H:mm') }}
              </td>
            </template>
            <template #modified="{item}">
              <td>
                {{ $moment(item.modified).format('DD/MM/YYYY H:mm') }}
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
      <template v-slot:footer>
        <button type="button"
                class="btn btn-secondary"
                @click="$refs.modalInvoiceDetail.hide($event)">
          {{ $t('Close') }}
        </button>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from "@/plugins/axios.js"

export default {
  name: 'TransactionInvoices',
  data() {
    return {
      items: [],
      search: "",
      listLoading: false,
      queryParams: {},
      page: 1,
      total_rows: 0,
      total_pages: 0,
      perpage: 20,
      invoiceDetailModal: false,
      invoiceDetail: null,
    }
  },
  computed: {
    invoiceListFields() {
      return [
        {
          key: "invoice_no",
          label: this.$t('invoice.InvoiceNo'),
        },
        {
          key: "amount_due",
          label: this.$t('invoice.AmountDue') + " (USDT)"
        },
        {
          key: "amount_received",
          label: this.$t('invoice.AmountPaid') + " (USDT)"
        },
        {
          key: "created",
          label: this.$t('invoice.Created')
        },
        {
          key: "modified",
          label: this.$t('invoice.Modified')
        }
      ]
    },
    billListFields() {
      return [
        {
          key: "bill_no",
          label: this.$t('bill.BillNo'),
        },
        // {
          // key: "subscription",
          // label: this.$t('bill.Subscription')
        // },
        {
          key: "item_name",
          label: this.$t('bill.ItemName')
        },
        {
          key: "amount",
          label: this.$t('bill.Amount') + '(USDT)'
        },
        {
          key: "status",
          label: this.$t('bill.Status')
        },
        {
          key: "created",
          label: this.$t('bill.Created')
        },
        {
          key: "modified",
          label: this.$t('bill.Modified')
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    showInvoiceDetail(invoiceNo) {
      this.invoiceDetailModal = true
      axios.get(`plan/invoice/${invoiceNo}/`).then(response => {
        this.invoiceDetail = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    pageChanged(page) {
      this.page = page
      this.fetchData()
    },
    paginationChanged(itemsPerPage) {
      this.perpage = itemsPerPage
      this.fetchData()
    },
    filterBySearch() {
      this.page = 1;
      this.fetchData();
    },
    fetchData() {
      this.listLoading = true;
      let filterParams = {
        page: this.page,
        perpage: this.perpage
      }
      if(this.search) {
        filterParams['search'] = this.search
      }

      return axios
        .get('plan/invoice/', {
          params: filterParams,
        })
        .then(response => {
          this.items = response.data.results
          this.total_rows = response.data.count
          this.total_pages = Math.ceil( this.total_rows / this.perpage )
          this.listLoading = false

        })
        .catch(error => {
          this.listLoading = false
          console.log(error)
        })
        .finally(() => {
          this.listLoading = false
        });
    }
  },
}
</script>

<style scoped>
.invoice-no {
  cursor: pointer;
  color: blue;
}
</style>
